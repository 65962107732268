<template>
  <b-row>
    <b-col
      lg="2"
      md="3"
      sm="3"
      class="my-1"
    >
      <!-- BOTON MOSTRAR -->
      <btnMostrar
        :pageOptions.sync="pageOptions"
        :perPage.sync="perPage"
        :total.sync="getCategorias.length"
      />
    </b-col>
    <b-col
      lg="6"
      md="5"
      sm="5"
      class="my-1"
    >
      <!-- FILTRO -->
      <inputFiltro
        :filter.sync="filter"
      />
    </b-col>
    <b-col
      md="4"
      sm="4"
      class="my-1"
    >
      <!-- BOTON CREAR -->
      <btnCrear
        texto="Inquilino"
        modulo="inquilinos"
        @processAdd="addInquilino"
      />
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="getCategorias"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template> -->

        <!-- COLUMNA ESTADO -->
        <template #cell(estado)="data">
          <colEstado
            :data="data"
            modulo="categorias"
            @processUpdateEstado="updateEstado"
          />
        </template>

        <!-- Column: Action -->
        <template #cell(acciones)="data">
          <colAccionesBtnes
            modulo="categorias"
            :data="data"
            :estado="data.item.destroy.estado"
            :cantidad="data.item.destroy.cantidad"
            @processGoToUpdate="goToUpdate"
            @processRemove="remove(data.item)"
          />
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination,
} from 'bootstrap-vue'
// import { mapGetters, mapActions, mapMutations } from 'vuex'

import colAccionesBtnes from '../../components/List/colAccionesBtnes.vue'
import btnCrear from '../../components/List/btnCrear.vue'
import inputFiltro from '../../components/List/inputFiltro.vue'
import btnMostrar from '../../components/List/btnMostrar.vue'
import colEstado from '../../components/List/colEstado.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,

    colAccionesBtnes,
    btnCrear,
    inputFiltro,
    btnMostrar,
    colEstado,
  },
  data() {
    return {
      getCategorias: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [3, 5, 10],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'estado', label: 'Estado', sortable: true },
        'acciones',
      ],
    }
  },
  computed: {
    // ...mapGetters({ getCategorias: 'categorias/getCategorias' }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    // getCategorias(val) {
    //   this.totalRows = val.length
    // },
  },
  mounted() {
    // this.fetchCategorias()
  },
  methods: {
    // ...mapActions({
    //   fetchCategorias: 'categorias/fetchCategorias',
    //   updateCategoriaEstado: 'categorias/updateCategoriaEstado',
    //   removeCategorias: 'categorias/removeCategorias',
    // }),
    // ...mapMutations('categorias', ['setCategoria']),
    addInquilino() {
      this.$router.replace({
        name: 'categorias-create',
      })
    },
    updateEstado(categoria) {
      this.$swal({
        title: 'Cambio de Estado!',
        text: `Estás seguro que deseas cambiar el estado de "${categoria.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateCategoriaEstado(categoria)
          this.$swal({
            icon: 'success',
            title: 'Estado actualizado!',
            text: `Se ha actualizado el estado de "${categoria.nombre}"!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    goToUpdate(categoria) {
      this.setCategoria(categoria)
      this.$router.push({
        name: 'categorias-update',
        params: { id: categoria.id },
      })
    },
    remove(categoria) {
      this.$swal({
        title: 'Eliminar categoría!',
        text: `Estás seguro que deseas eliminar la categoría "${categoria.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeCategorias(categoria.id)
          this.$swal({
            icon: 'success',
            title: 'Eliminada con éxito!',
            text: `"${categoria.nombre}" ha sido eliminada!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
