<template>
  <div>
    <b-card>
      <inquilinosList />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import inquilinosList from './components/InquilinosList.vue'

// import inquilinosList from './components/InquilinosList.vue'

export default {
  components: {
    BCard,
    inquilinosList,
  },
  computed: {
    // ...mapGetters({ usuarios }),
  },
  created() {
    // Consultar autenticación del
  },
}
</script>

<style>

</style>
